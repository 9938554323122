<template>
  <div class="box_bg">
    <div class="box_title">
      <div>
        {{ title }}
      </div>
      <slot name="header"></slot>
    </div>
    <div class="contentTable">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "billBox",
  props: {
    title: {
      type: String,
      default: "风险标注",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
/* 当屏幕宽度小于或等于768像素时，应用以下样式 */
@media (min-width: 768px) {
  .box_bg {
    position: relative;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 24px 27px;
    background-color: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 16px 16px 16px 16px;
    .box_title {
      position: relative;
      display: flex;
      align-items: center;
      font-weight: 700;
      font-size: 20px;
      padding: 6px 0 6px 11px;
      margin-bottom: 20px;
      background: rgba(1, 143, 255, 0.06);
      border-left: #018fff 4px solid;
    }
    .contentTable {
      // padding: 0 20px;
    }
  }
}

/* 当屏幕宽度小于或等于768像素时，应用以下样式 */
@media (max-width: 768px) {
  .box_bg {
    position: relative;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    .box_title {
      position: relative;
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 20px;
      padding: 6px;
      margin-bottom: 10px;
      background: rgba(1, 143, 255, 0.06);
      border-left: #018fff 4px solid;
    }
    .contentTable {
      // padding: 0 10px;
    }
  }
}
</style>
