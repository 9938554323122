<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <!-- <div class="money">
      <div class="num_text">下月应付金额（¥）</div>
      <div class="num_box">100~300</div>
    </div> -->
    <el-table
      :data="tableData"
      border
      style="width: 100%"
      height="350px"
      empty-text="暂无数据"
    >
      <el-table-column
        prop="period"
        label="周期"
        show-overflow-tooltip
      ></el-table-column>

      <el-table-column
        prop="platformNum"
        label="申请平台"
        show-overflow-tooltip
      />
      <el-table-column prop="applyNum" label="申请次数" show-overflow-tooltip />
      <el-table-column
        prop="cancelNum"
        label="取消次数"
        show-overflow-tooltip
      />
      <el-table-column prop="hireNum" label="在租台数" show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="secDepositAmt"
        label="支付押金"
        show-overflow-tooltip
      >
        <template #default="scope">
          <div>{{ scope.row.secDepositAmt.toFixed(2) }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="paidAmt"
        label="已支付金额(￥)"
        width="120px"
        show-overflow-tooltip
      >
        <template #default="scope">
          <div>{{ scope.row.paidAmt.toFixed(2) }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="residualAmt"
        label="剩余总债权(￥)"
        width="120px"
        show-overflow-tooltip
      >
        <template #default="scope">
          <div>{{ scope.row.residualAmt.toFixed(2) }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="tips">注：本数据由大模型识别生成，可能存在误差。</div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableData: [],
    };
  },
  watch: {
    list() {
      this.initData();
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      let typeData = {
        FORECAST7: {
          text: "7天内",
          data: {
            platformNum: 0, //平台数量
            applyNum: 0, //申请次数
            cancelNum: 0, //取消数量
            hireNum: 0, //在租数量
            secDepositAmt: 0, //支付押金
            paidAmt: 0, //已付金额
            residualAmt: 0, //剩余金额
          },
        },
        FORECAST15: {
          text: "15天内",
          data: {
            platformNum: 0, //平台数量
            applyNum: 0, //申请次数
            cancelNum: 0, //取消数量
            hireNum: 0, //在租数量
            secDepositAmt: 0, //支付押金
            paidAmt: 0, //已付金额
            residualAmt: 0, //剩余金额
          },
        },
        FORECAST30: {
          text: "30天内",
          data: {
            platformNum: 0, //平台数量
            applyNum: 0, //申请次数
            cancelNum: 0, //取消数量
            hireNum: 0, //在租数量
            secDepositAmt: 0, //支付押金
            paidAmt: 0, //已付金额
            residualAmt: 0, //剩余金额
          },
        },
        FORECAST90: {
          text: "90天内",
          data: {
            platformNum: 0, //平台数量
            applyNum: 0, //申请次数
            cancelNum: 0, //取消数量
            hireNum: 0, //在租数量
            secDepositAmt: 0, //支付押金
            paidAmt: 0, //已付金额
            residualAmt: 0, //剩余金额
          },
        },
        FORECAST180: {
          text: "180天内",
          data: {
            platformNum: 0, //平台数量
            applyNum: 0, //申请次数
            cancelNum: 0, //取消数量
            hireNum: 0, //在租数量
            secDepositAmt: 0, //支付押金
            paidAmt: 0, //已付金额
            residualAmt: 0, //剩余金额
          },
        },
        FORECAST365: {
          text: "365天内",
          data: {
            platformNum: 0, //平台数量
            applyNum: 0, //申请次数
            cancelNum: 0, //取消数量
            hireNum: 0, //在租数量
            secDepositAmt: 0, //支付押金
            paidAmt: 0, //已付金额
            residualAmt: 0, //剩余金额
          },
        },
      };
      this.tableData = [];
      this.list.forEach((item) => {
        for (let key in typeData[item.type].data) {
          typeData[item.type].data[key] += Number(item[key]);
        }
      });
      for (let key in typeData) {
        this.tableData.push({
          period: typeData[key].text,
          ...typeData[key].data,
        });
      }
      // console.log(this.tableData, "tableDatatableDatatableData");
    },
    indexMethod(index) {
      return index + 1;
    },
  },
};
</script>

<style lang="scss">
.money {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  .num_text {
    font-size: 15px;
    color: #383838;
  }
  .num_box {
    font-size: 28px;
    color: #383838;
    margin-left: 6px;
    color: #ff991c;
    font-weight: 800;
  }
}
.tips {
  padding: 13px 0 0 0;
  font-size: 14px;
  color: #383838;
}
</style>
