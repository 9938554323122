<template>
  <div class="bestsellers-container">
    <ChartWordCloud :options="{series}" @chartsClick="chartsClick"></ChartWordCloud>
  </div>
</template>
   
  <script>
import ChartWordCloud from "./ChartWordCloud.vue";

export default {
  name: "LineChart",
  components: {
    ChartWordCloud,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
        series: [],
    };
  },
  watch: {
    list() {
      this.initData();
    },
  },
  mounted() {
    // console.log(this.list,'1111')
    this.initData();
     
  },
  methods: {
    chartsClick(e){
      this.$emit('chartsClick',e)
    },
    initData() {

      let list = [];
      this.list.forEach((item) => {
        list.push({ name: item.word, value: item.times });
      });
      this.series = [
        {
          gridSize: 20,
          data: list,
        },
      ];

    },
  },
};
</script>
   
<style scoped lang="scss">
.bestsellers-container {
  background: #fff;
  height: 520px;
  #charts-content {
    /* 需要设置宽高后才会显示 */
    width: 100%;
    height: 100%;
  }
}
</style>