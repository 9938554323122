<!-- eslint-disable vue/no-deprecated-filter -->
<template>
  <div class="bill_pc">
    <div class="max-search">
      <div class="search">
        <div class="hint">关键词搜索</div>
        <el-input class="keyword" v-model="keyword" placeholder="请输入内容" />
        <div class="blue" @click="search">
          <img src="@/assets/search.png" alt="" />
          搜索
        </div>
        <div class="gray_btn" @click="clearSearch">
          <img src="@/assets/clearSearch.png" alt="" />
          清空
        </div>
        <div v-if="isMore" class="gray_btn" @click="isMore = false">
          <img src="@/assets/isMore.png" alt="" />
          收起
        </div>
        <div v-if="!isMore" class="gray_btn" @click="isMore = true">
          <img src="@/assets/isMore.png" alt="" />
          更多
        </div>
      </div>
      <div v-if="isMore">
        <div class="rep_type">
          <div class="rep_type_title">账单类型</div>
          <div
            class="rep_type_content rep_type_content_pc"
            :class="selectPlatform == item.type ? 'typeActive' : ''"
            v-for="(item, index) in platformList"
            :key="index"
            @click="selectPlatform = item.type"
          >
            <div class="">{{ item.text }}</div>
          </div>
        </div>

        <div class="rep_type">
          <div class="rep_type_title">收/支/其他</div>
          <div
            class="rep_type_content rep_type_content_pc"
            :class="billType == item.type ? 'typeActive' : ''"
            v-for="(item, index) in billTypeList"
            :key="index"
            @click="billType = item.type"
          >
            <div class="">{{ item.text }}</div>
          </div>
        </div>

        <div class="rep_type">
          <div class="rep_type_title">时间</div>
          <div
            class="rep_type_content rep_type_content_time"
            v-for="(item, index) in timeList"
            :key="index"
            @click="setDateValue(item.type)"
          >
            <div class="">{{ item.text }}</div>
          </div>
          <div>
            <el-date-picker
              v-model="dateValue"
              type="daterange"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
            />
          </div>
        </div>

        <div class="rep_type">
          <div class="rep_type_title">金额</div>
          <div
            class="rep_type_content rep_type_content_time"
            v-for="(item, index) in numList"
            :key="index"
            @click="setMaxMin(item)"
          >
            <div class="">{{ item.text }}</div>
          </div>
          <div class="flex-align">
            <el-input
              v-model="minValue"
              style="width: 100px"
              placeholder="Please input"
              :formatter="
                (value) => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              "
              :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
            />
            <div>-</div>
            <el-input
              v-model="maxValue"
              style="width: 100px"
              placeholder="Please input"
              :formatter="
                (value) => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              "
              :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="min-search">
      <div class="search">
        <div class="hint" style="font-size: 12px">关键词</div>
        <el-input class="keyword" v-model="keyword" placeholder="请输入内容" />
        <div class="blue" @click="clearSearch">清空</div>
        <div v-if="isMore" class="blue" @click="isMore = false">收起</div>
        <div v-if="!isMore" class="blue" @click="isMore = true">更多</div>
      </div>

      <div v-if="isMore" class="isShowMore">
        <div class="rep_type">
          <div class="rep_type_title">账单类型</div>
          <div
            class="rep_type_content rep_type_content_pc"
            :class="selectPlatform == item.type ? 'typeActive' : ''"
            v-for="(item, index) in platformList"
            :key="index"
            @click="selectPlatform = item.type"
          >
            <div class="">{{ item.text }}</div>
          </div>
        </div>

        <div class="rep_type">
          <div class="rep_type_title">收/支/其他</div>
          <div
            class="rep_type_content rep_type_content_pc"
            :class="billType == item.type ? 'typeActive' : ''"
            v-for="(item, index) in billTypeList"
            :key="index"
            @click="billType = item.type"
          >
            <div class="">{{ item.text }}</div>
          </div>
        </div>

        <div class="rep_type">
          <div class="rep_type_title">时间</div>
          <div
            class="rep_type_content rep_type_content_time"
            v-for="(item, index) in timeList"
            :key="index"
            @click="setDateValue(item.type)"
          >
            <div class="">{{ item.text }}</div>
          </div>
          <div>
            <el-date-picker
              style="width: 300px; margin-top: 5px"
              v-model="dateValue"
              type="daterange"
              popper-class="custom-date-picker-popper"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
            />
          </div>
        </div>

        <div class="rep_type" style="margin-top: 10px">
          <div class="rep_type_title">金额</div>
          <div
            class="rep_type_content rep_type_content_time"
            v-for="(item, index) in numList"
            :key="index"
            @click="setMaxMin(item)"
          >
            <div class="">{{ item.text }}</div>
          </div>
          <div
            class="flex-align"
            style="margin: 5px; justify-content: space-between"
          >
            <el-input
              v-model="minValue"
              style="width: 45%"
              placeholder="Please input"
              :formatter="
                (value) => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              "
              :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
            />
            <div>-</div>
            <el-input
              v-model="maxValue"
              style="width: 45%"
              placeholder="Please input"
              :formatter="
                (value) => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              "
              :parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
            />
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: center; margin-bottom: 10px">
        <div class="blue_btn" @click="search">搜索</div>
      </div>
    </div>

    <el-table
      :data="tableData"
      border
      style="width: 100%"
      height="440px"
      empty-text="暂无数据"
    >
      <el-table-column
        type="index"
        width="60px"
        label="序号"
        :index="indexMethod"
      />
      <el-table-column prop="platform" label="平台" show-overflow-tooltip>
        <template #default="scope">
          <div>{{ filterData(scope.row.platform, "platformList") }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="numberTrades"
        label="收/支/其他"
        show-overflow-tooltip
      >
        <template #default="scope">
          <div>{{ filterData(scope.row.inOut, "billTypeList") }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="counterparty"
        label="交易对方"
        show-overflow-tooltip
      />
      <el-table-column
        prop="orderMode"
        label="收/付款方式"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="amount"
        label="金额"
        sortable
        :sort-method="(a,b)=>{return a.amount - b.amount}"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="orderDate" label="时间" show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="orderInstructions"
        show-overflow-tooltip
        label="交易描述"
      >
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="80px">
        <template #default="scope">
          <!-- <div class="btn_class" @click="open(scope.row)"> 定位 </div> -->
          <div class="btn_class" @click="open(scope.row)">反馈</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination_box">
      <div class="billTotal">
        <div>总收入：{{ inAmount }}</div>
        <div>总支出：{{ outAmount }}</div>
        <div>其他：{{ otherAmount }}</div>
      </div>
      <el-pagination
        size="small"
        background
        :page-sizes="[10, 20, 50, 100]"
        :current-page="currentPage"
        :page-size="pageSize"
        layout="total, sizes, jumper, prev, pager,next"
        :total="tableList.length"
        style="flex-wrap: wrap; line-height: 50px"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      name: "",
    },
    list: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      currentPage: 1,
      tag: "",
      pageSize: 10,
      dateValue: [],
      minValue: "",
      maxValue: "",
      keyword: "",
      isMore: false,
      tableList: [],
      tableData: [],
      selectPlatform: 0,
      platformList: [
        {
          type: 0,
          text: "全部",
        },
        {
          type: "WX",
          text: "微信",
        },
        {
          type: "ALIPAY",
          text: "支付宝",
        },
      ],
      billType: 0,
      billTypeList: [
        {
          type: 0,
          text: "全部",
        },
        {
          type: "收入",
          text: "收入",
        },
        {
          type: "支出",
          text: "支出",
        },
        {
          type: "不计收支",
          text: "其他",
        },
      ],
      timeList: [
        {
          type: 30,
          text: "近1个月",
        },
        {
          type: 60,
          text: "近2个月",
        },
        {
          type: 90,
          text: "近3个月",
        },
        {
          type: 365,
          text: "1年",
        },
      ],

      numList: [
        {
          max: 500,
          min: 0,
          text: "0-500",
        },
        {
          max: 1000,
          min: 500,
          text: "500-1000",
        },
        {
          max: 2000,
          min: 1000,
          text: "1000-2000",
        },
        {
          max: 3500,
          min: 2000,
          text: "2000-3500",
        },
        {
          max: 10000000,
          min: 3500,
          text: "3500+",
        },
      ],
    };
  },
  watch: {
    list() {
      this.tableList = JSON.parse(JSON.stringify(this.list));
      this.paginate();
    },
  },
  computed: {
    inAmount() {
      let num = 0;
      this.tableList.forEach((item) => {
        if (item.inOut === "收入") {
          num += Number(item.amount);
        }
      });
      let numText = this.formatNumber(num);
      return numText;
    },
    outAmount() {
      let num = 0;
      this.tableList.forEach((item) => {
        if (item.inOut === "支出") {
          num += Number(item.amount);
        }
      });
      let numText = this.formatNumber(num);
      return numText;
    },
    otherAmount() {
      let num = 0;
      this.tableList.forEach((item) => {
        if (item.inOut === "不计收支") {
          num += Number(item.amount);
        }
      });
      let numText = this.formatNumber(num);
      return numText;
    },
  },
  mounted() {
    this.tableList = JSON.parse(JSON.stringify(this.list));
    this.paginate();
  },
  methods: {
    formatNumber(num) {
      // console.log(num);
      if (!num) {
        return;
      }
      if (num >= 1000 && num < 10000) {
        return (num / 1000).toFixed(2) + "K";
      } else if (num >= 10000) {
        return (num / 10000).toFixed(2) + "W";
      } else {
        return num.toFixed(2).toString();
      }
    },
    initTag(tag) {
      this.tag = tag;
      this.minValue = "";
      this.maxValue = "";
      this.keyword = "";
      this.selectPlatform = 0;
      this.billType = 0;
      this.dateValue = [];
      let isKeyword = false;
      let isSelectPlatform = false;
      let isBillType = false;
      let isDateValue = false;
      let isMaxMin = false;
      this.tableList = [];
      let item = {};
      this.currentPage = 1;
      for (let i = 0; i < this.list.length; i++) {
        isKeyword = false;
        isSelectPlatform = false;
        isBillType = false;
        isDateValue = false;
        isMaxMin = false;
        item = this.list[i];

        // 字符串检索
        if (this.tag) {
          for (let key in item) {
            if (typeof item[key] == "string") {
              isKeyword = isKeyword ? isKeyword : item[key].includes(this.tag);
            }
          }
          if (!isKeyword) continue;
        } else {
          isKeyword = true;
        }
        // 判断平台
        if (this.selectPlatform != 0) {
          isSelectPlatform = this.selectPlatform == item.platform;
          if (!isSelectPlatform) continue;
        } else {
          isSelectPlatform = true;
        }
        // 判断交易类型
        if (this.billType != 0) {
          isBillType = this.billType == item.inOut;
          if (!isBillType) continue;
        } else {
          isBillType = true;
        }
        // 判断时间
        if (this.dateValue.length != 0) {
          isDateValue = this.isFormattedDateInRange(item.orderDate);
          if (!isDateValue) continue;
        } else {
          isDateValue = true;
        }
        // 判断金额
        if (this.minValue && this.maxValue) {
          isMaxMin =
            item.amount >= this.minValue && item.amount <= this.maxValue;
        } else if (this.minValue && !this.maxValue) {
          isMaxMin = item.amount >= this.minValue;
        } else if (!this.minValue && this.maxValue) {
          isMaxMin = item.amount <= this.maxValue;
        } else {
          isMaxMin = true;
        }

        if (
          isKeyword &&
          isSelectPlatform &&
          isBillType &&
          isDateValue &&
          isMaxMin
        ) {
          this.tableList.push(item);
        }
      }
      this.paginate();
    },
    initName(name) {
      this.keyword = name;
      this.search();
    },
    initIds(ids) {
      this.tableList = [];
      this.list.forEach((item) => {
        if (ids.indexOf(item.id) != -1) {
          this.tableList.push(item);
        }
      });
      this.paginate();
    },
    filterData(value, item) {
      let text = "";
      if (item) {
        this[item].forEach((key) => {
          if (value == key.type) {
            text = key.text;
          }
        });
      }
      return text;
    },
    search() {
      let isKeyword = false;
      let isSelectPlatform = false;
      let isBillType = false;
      let isDateValue = false;
      let isMaxMin = false;
      this.tableList = [];
      let item = {};
      this.currentPage = 1;
      for (let i = 0; i < this.list.length; i++) {
        isKeyword = false;
        isSelectPlatform = false;
        isBillType = false;
        isDateValue = false;
        isMaxMin = false;
        item = this.list[i];

        // 字符串检索
        if (this.keyword) {
          for (let key in item) {
            if (typeof item[key] == "string") {
              isKeyword = isKeyword
                ? isKeyword
                : item[key].includes(this.keyword);
            }
          }
          if (!isKeyword) continue;
        } else {
          isKeyword = true;
        }
        // 判断平台
        if (this.selectPlatform != 0) {
          isSelectPlatform = this.selectPlatform == item.platform;
          if (!isSelectPlatform) continue;
        } else {
          isSelectPlatform = true;
        }
        // 判断交易类型
        if (this.billType != 0) {
          isBillType = this.billType == item.inOut;
          if (!isBillType) continue;
        } else {
          isBillType = true;
        }
        // 判断时间
        if (this.dateValue.length != 0) {
          isDateValue = this.isFormattedDateInRange(item.orderDate);
          if (!isDateValue) continue;
        } else {
          isDateValue = true;
        }
        // 判断金额
        if (this.minValue && this.maxValue) {
          isMaxMin =
            item.amount >= this.minValue && item.amount <= this.maxValue;
        } else if (this.minValue && !this.maxValue) {
          isMaxMin = item.amount >= this.minValue;
        } else if (!this.minValue && this.maxValue) {
          isMaxMin = item.amount <= this.maxValue;
        } else {
          isMaxMin = true;
        }

        if (
          isKeyword &&
          isSelectPlatform &&
          isBillType &&
          isDateValue &&
          isMaxMin
        ) {
          this.tableList.push(item);
        }
      }
      this.paginate();
    },

    handleSizeChange(val) {
      // console.log(`${val} items per page`);
      this.pageSize = val;
      this.paginate();
    },
    handleCurrentChange(val) {
      // console.log(`current page: ${val}`);
      this.currentPage = val;
      this.paginate();
    },
    paginate() {
      let page_number = this.currentPage;
      let page_size = this.pageSize;
      // 计算分页开始的索引
      const start = (page_number - 1) * page_size;
      // 使用slice方法获取当前页的数据
      this.tableData = this.tableList.slice(start, start + page_size);
    },
    isFormattedDateInRange(formattedDate) {
      const dateToCheck = new Date(formattedDate);
      let startDate = new Date(this.dateValue[0]);
      let endDate = new Date(this.dateValue[1]);
      return dateToCheck >= startDate && dateToCheck <= endDate;
    },
    clearSearch() {
      this.tag = "";
      this.keyword = "";
      this.dateValue = [];
      this.minValue = "";
      this.maxValue = "";
      this.billType = 0;
      this.selectPlatform = 0;
      this.search();
    },
    setDateValue(days) {
      const endDate = new Date();
      const startDate = new Date();
      startDate.setDate(endDate.getDate() - days);
      this.dateValue = [startDate, endDate];
    },
    setMaxMin(item) {
      this.minValue = item.min;
      this.maxValue = item.max;
    },
    indexMethod(index) {
      return index + 1;
    },
    open() {},
  },
};
</script>

<style lang="scss">
.bill_pc {
  @media (min-width: 768px) {
    .search {
      height: 50px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      position: relative;
      .hint {
        color: #b6b6b6;
      }
    }
    .max-search {
      display: block;
    }
    .min-search {
      display: none;
    }
    .keyword {
      width: 200px;
      margin-left: 20px;
      height: 30px;
      border: none;
      background: #f7f7f7;
      border-radius: 5px;
    }
    .blue {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 74px;
      height: 38px;
      line-height: 38px;
      margin-left: 20px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      font-weight: 700;
      background: #018fff;
      border-radius: 6px;
      font-size: 14px;
      &:active {
        background: #165dff;
        color: #fff;
      }
      img {
        width: 16px;
        height: auto;
        margin-right: 5px;
      }
    }
    .gray_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 74px;
      height: 38px;
      line-height: 38px;
      margin-left: 20px;
      text-align: center;
      cursor: pointer;
      font-weight: 700;
      border-radius: 6px;
      background: #f4f4f5;
      color: #8393a3;
      font-size: 14px;
      border: 1px solid #dcdfe6;
      img {
        width: 16px;
        height: auto;
        margin-right: 5px;
      }
    }
    .pagination_box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      line-height: 50px;
      .billTotal {
        display: flex;
        font-size: 14px;
        color: #818799;
        line-height: 50px;
        div {
          margin-right: 15px;
        }
      }
    }
    .rep_type {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .rep_type_title {
        width: 100px;
        color: #b6b6b6;
      }
      .rep_type_content_pc {
        border-radius: 20px;
        background: #fff;
        border: 1px solid #383838;
        text-align: center;
      }
      .rep_type_content {
        width: 78px;
        height: 32px;
        margin-right: 30px;
        cursor: pointer;
        height: 100%;
        line-height: 32px;
      }
      .rep_type_content_time {
        width: 90px;
      }
      .typeActive {
        color: #185eff;
        background: #c7e1ff;
        border: 1px solid #2a82e4;
      }
    }
  }
  @media (max-width: 768px) {
    .search {
      height: 50px;
      display: flex;
      align-items: center;
      position: relative;
      .hint {
        color: #b6b6b6;
      }
    }
    .max-search {
      display: none !important;
    }
    .min-search {
      display: block;
    }
    .keyword {
      width: 190px;
      margin-left: 5px;
      height: 30px;
      border: none;
      background: #f7f7f7;
      border-radius: 5px;
    }
    .blue {
      height: 38px;
      line-height: 38px;
      margin-left: 10px;
      text-align: center;
      color: #185eff;
      cursor: pointer;
      font-weight: 700;
    }
    .gray_btn {
      height: 38px;
      line-height: 38px;
      margin-left: 10px;
      text-align: center;
      color: #185eff;
      cursor: pointer;
      font-weight: 700;
    }
    .blue_btn {
      width: 80%;
      height: 38px;
      line-height: 38px;
      text-align: center;
      color: #185eff;
      cursor: pointer;
      font-weight: 700;
      background: #e8f3ff;
      border-radius: 6px;
      &:active {
        background: #165dff;
        color: #fff;
      }
    }
    .pagination_box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .billTotal {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        color: #818799;
        div {
          margin-right: 10px;
        }
      }
    }
    .isShowMore {
      margin-top: 8px;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      padding: 10px 0 5px 10px;
      margin-bottom: 10px;
    }
    .rep_type {
      display: flex;
      align-items: center;
      font-size: 14px;
      flex-wrap: wrap;
      margin: 10px 0;
      .rep_type_title {
        color: #b6b6b6;
        font-size: 14px;
      }
      .rep_type_content_pc {
        border-radius: 20px;
        background: #fff;
        border: 1px solid #383838;
        text-align: center;
      }
      .rep_type_content {
        margin-left: 10px;
        cursor: pointer;
        border: 1px solid #fff;
        padding: 2px 10px;
      }
      .rep_type_content_time {
        // width: 90px;
      }
      .typeActive {
        color: #185eff;
        border: 1px solid #2a82e4;
        font-weight: 700;
        background: #c7e1ff;
        border-radius: 20px;
      }
    }
  }
}

.custom-date-picker-popper .el-picker-panel__body {
  display: flex;
  flex-direction: column;
}
</style>
