<template>
  <billBox title="交易对方-列表TOP">
    <template v-slot:header>
      <div class="top_box">
        <div class="top_class">
          <div
            v-for="(item, index) in topList"
            :key="index"
            class="top_item"
            @click="selectTop(index)"
            :class="index === selectIndex ? 'top_active' : ''"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
    </template>

    <div class="counterparty-box">
      <el-tabs
        v-model="activeName"
        class="demo-tabs"
        type="card"
        @tab-click="handleClick"
      >
        <el-tab-pane
          v-for="(item, index) in tabList"
          :key="index"
          :label="item.text"
          :name="item.num"
        >
          <el-table
            v-if="selectIndex == 0"
            ref="myTable"
            :data="tableData"
            :default-sort="defaultSort"
            border
            height="300px"
            style="width: 100%; margin-top: 10px"
            :sort-change="sortTable"
            empty-text="暂无数据"
          >
            <el-table-column
              type="index"
              width="60px"
              label="序号"
              :index="indexMethod"
            />
            <el-table-column
              prop="tradeName"
              label="交易对象"
              width="130px"
            ></el-table-column>
            <el-table-column
              prop="tradeTimes"
              label="总次数"
              width="100px"
              sortable
            />
            <!-- -->
            <el-table-column prop="totalAmount" label="总额" />
            <el-table-column prop="outAmount" label="总支出" />
            <el-table-column prop="inAmount" label="总收入" />
            <el-table-column prop="otherAmount" label="其他" />
            <el-table-column label="操作" fixed="right">
              <template #default="scope">
                <div class="btn_class" @click="open(scope.row)">详情</div>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-if="selectIndex == 1"
            ref="myTable"
            :data="tableData"
            border
            height="300px"
            style="width: 100%; margin-top: 10px"
            :default-sort="defaultSort"
            empty-text="暂无数据"
          >
            <el-table-column
              type="index"
              width="60px"
              label="序号"
              :index="indexMethod"
            />
            <el-table-column
              prop="tradeName"
              label="交易对象"
              width="130px"
            ></el-table-column>
            <el-table-column prop="tradeTimes" label="总次数" width="100px" />
            <!-- -->
            <el-table-column
              prop="totalAmount"
              label="总额"
              sortable
              :sort-method="
                (a, b) => {
                  return a.totalAmount - b.totalAmount;
                }
              "
            />
            <el-table-column prop="outAmount" label="总支出" />
            <el-table-column prop="inAmount" label="总收入" />
            <el-table-column prop="otherAmount" label="其他" />
            <el-table-column label="操作" fixed="right">
              <template #default="scope">
                <div class="btn_class" @click="open(scope.row)">详情</div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <el-drawer v-model="drawer" size="90%" direction="rtl">
        <billContent ref="bill" :list="allTradeList"></billContent>
      </el-drawer>
    </div>
  </billBox>
</template>

<script>
import billContent from "@/components/billContent/billContent.vue";
import billBox from "@/components/billBox/billBox.vue";

export default {
  components: {
    billBox,
    billContent,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    allTradeList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      drawer: false,
      activeName: "topTrade7",
      keyword: "",
      tabList: [
        {
          text: "7天",
          num: "topTrade7",
        },
        {
          text: "15天",
          num: "topTrade15",
        },
        {
          text: "1个月",
          num: "topTrade30",
        },
        {
          text: "3个月",
          num: "topTrade90",
        },
        {
          text: "半年",
          num: "topTrade180",
        },
        {
          text: "1年",
          num: "topTrade365",
        },
      ],
      selectIndex: 0,
      topList: [
        {
          text: "频次TOP",
          sort: { prop: "tradeTimes", order: "descending" },
        },
        {
          text: "金额TOP",
          sort: { prop: "totalAmount", order: "descending" },
        },
      ],
      tableData: [],
      defaultSort: { prop: "tradeTimes", order: "descending" },
    };
  },
  watch: {
    list() {
      this.initData();
    },

    activeName() {
      this.initData();
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    selectTop(index) {
      this.selectIndex = index;
      this.defaultSort = this.topList[index].sort;
    },

    initData() {
      this.tableData = [];
      this.list.forEach((item) => {
        if (this.activeName == item.type) this.tableData.push(item);
      });
      this.selectTop(0);
    },
    handleClick() {},
    indexMethod(index) {
      return index + 1;
    },
    open(row) {
      this.drawer = true;
      this.keyword = row.tradeName;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.bill.initName(this.keyword);
        }, 300);
      });
    },
  },
};
</script>

<style lang="scss">
.bankCardLastNumber {
  display: flex;
  justify-content: flex-start;
  position: relative;
  text-align: center;
  .tagImgBox {
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    .tagImg {
      height: 21px;
      width: 21px;
      margin-left: 5px;
    }
  }
}

@media (min-width: 768px) {
  .counterparty-box {
    .el-tabs__header {
      display: flex !important;
      justify-content: end !important;
      margin-bottom: 0;
      border-bottom: 0;
      .el-tabs__nav {
        border: 0;
      }
      .el-tabs__nav-scroll {
        display: flex !important;
        justify-content: end !important;
      }
    }

    .el-tabs__item {
      border-radius: 5px;
      background-color: #f4f4f5;
      margin: 0 10px;
      color: #8393a3;
      padding: 0 20px !important;
    }
    .el-tabs__item.is-active {
      border-radius: 5px;
      background-color: #018fff;
      color: #fff;
    }
  }
}

@media (max-width: 768px) {
  .counterparty-box {
    .el-tabs__header {
      display: flex !important;
      justify-content: end !important;
      padding-right: 20px;
      margin-bottom: 0;
      border-bottom: 0;
      .el-tabs__nav {
        border: 0;
      }
    }

    .el-tabs__item {
      border-radius: 5px;
      background-color: #f4f4f5;
      margin: 0 10px;
      padding: 0 20px !important;
      color: #8393a3;
    }
    .el-tabs__item.is-active {
      border-radius: 5px;
      background-color: #018fff;
      color: #fff;
    }
  }
}
.top_box {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-right: 10px;
  .top_class {
    display: flex;
    background: #fff;
    color: #018fff;
    border-radius: 30px;
    .top_item {
      font-size: 14px;
      flex: 1;
      padding: 4px 10px;
      cursor: pointer;
    }
    .top_active {
      background: #018fff;
      color: #f0f8ff;
      border-radius: 30px;
    }
  }
}
</style>
