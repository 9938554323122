<template>
  <div>
    <billBox title="支付方式">
      <template v-slot:header>
        <div class="top_box2">
          <div class="top_class">
            <div
              v-for="(item, index) in topList"
              :key="index"
              class="top_item"
              @click="selectTop(index)"
              :class="index === selectIndex ? 'top_active' : ''"
            >
              {{ item.text }}
            </div>
          </div>
        </div>
      </template>

      <div v-if="!showChart">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          height="360px"
          empty-text="暂无数据"
        >
          <el-table-column
            type="index"
            width="60px"
            label="序号"
            :index="indexMethod"
            
          />
          <el-table-column prop="bankName" label="支付方式" width="150px" align="center">
            <template #default="scope">
              <div>
                <div>{{ scope.row.bankName }}</div>
                <div v-if="scope.$index == 0" class="zhifubaoTag">常用</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="platform" label="平台" center min-width="90px">
            <template #default="scope">
              <div>
                <div class="flex-align bankCard">
                  <div>
                    <!-- {{ scope.row.bankCard }} -->
                  </div>
                  <div class="tagImgBox">
                    <img
                      class="tagImg"
                      v-if="
                        scope.row.platform == 'WX' ||
                        scope.row.platform == 'TWO'
                      "
                      src="@/assets/wx.png"
                    />

                    <img
                      class="tagImg"
                      v-if="
                        scope.row.platform == 'ALIPAY' ||
                        scope.row.platform == 'TWO'
                      "
                      src="@/assets/zfb.png"
                    />
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="tradeTimes" label="交易次数" min-width="90px">
            <template #default="scope">
              <div style="display: flex; align-items: center">
                <div style="width: 40%; text-align: center">
                  {{ scope.row.tradeTimes }}
                </div>
                <div style="margin: 0 5px">/</div>
                <div style="width: 55%; text-align: center">
                  {{ Number(scope.row.tradeRate).toFixed(2) }}%
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="inAmount" label="入账金额" />
          <el-table-column prop="outAmount" label="出账金额"></el-table-column>
        </el-table>
      </div>
      <div v-if="showChart">
        <div style="width: 100%; height: 400px; overflow: auto">
          <div
            ref="radarChart"
            style="width: 100%; min-width: 600px; height: 100%"
          ></div>
        </div>
      </div>
    </billBox>
  </div>
</template>

<script>
import billBox from "@/components/billBox/billBox.vue";
import * as echarts from "echarts";

export default {
  components: { billBox },
  props: {
    list: {
      type: Array,
      default: () => [
        {
          bankName: "邮储银行储蓄卡",
          bankCardLastNumber: "2877",
          numberTrades: "1259 / 85.71%",
          recordedAmount: "68593.02",
          disbursementAmount: "112869.6",
        },
      ],
    },
    bankRadarData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showChart: false,
      tableData: [],
      selectIndex: 0,
      topList: [
        {
          text: "银行卡",
        },
        {
          text: "比例图",
        },
      ],
    };
  },
  watch: {
    list() {
      this.tableData = [...this.list];
      // this.initRadarChart()
    },
    bankRadarData() {
      this.$nextTick(() => {
        if(this.showChart){
          this.initRadarChart();
        }
       
      });
    },
  },
  mounted() {
    this.tableData = [...this.list];
  },
  methods: {
    selectTop(index) {
      this.selectIndex = index;
      this.showChart = index === 1;
      this.$nextTick(() => {
        if(this.showChart){
          this.initRadarChart();
        }
       
      });
    },

    indexMethod(index) {
      return index + 1;
    },
    initRadarChart() {
      const chartDom = this.$refs.radarChart;
      const myChart = echarts.init(chartDom);
      let legend = this.bankRadarData.legend
      let indicator = this.bankRadarData.indicator
      let seriesData = []
      this.bankRadarData.data.forEach(item => {
        seriesData.push({
          value: item.data,
          name: item.name,
        })
      })
      const option = {
        legend: {
          data: legend,
          bottom:0,
        },
        radar: {
          axisName: {
            formatter: "【{value}】",
            color: "#018fff",
          },

          // shape: 'circle',
          indicator: indicator,
        },
        series: [
          {
            name: "Budget vs spending",
            type: "radar",
            data: seriesData,
            axisLine: {
              lineStyle: {
                color: "rgba(211, 253, 250, 0.8)",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(211, 253, 250, 0.8)",
              },
            },
            label: {
              show: true,
              formatter: function (params) {
                return params.value;
              },
            },
          },
        ],
      };

      myChart.setOption(option);
    },
  },
};
</script>

<style lang="scss">
.bankCard {
  display: flex;
  justify-content: flex-start;
  position: relative;
  .tagImgBox {

    display: flex;
    align-items: center;

    .tagImg {
      height: 21px;
      width: 21px;
      margin-left: 5px;
    }
  }
}

.zhifubaoTag {
  background-color: #ffa46b;
  color: #fff;
  text-align: center;
  position: absolute;
  left: -29px;
  top: 15px;
  transform: rotate(-45deg);
  font-size: 10px;
  width: 100px;
  z-index: 99;
  height: 18px;
  line-height: 20px;
}

.el-table--enable-row-transition .el-table__body td.el-table__cell {
  overflow: hidden;
}

.top_box2 {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  margin-right: 10px;

  .top_class {
    display: flex;
    background: #fff;
    color: #018fff;
    border-radius: 30px;

    .top_item {
      font-size: 14px;
      flex: 1;
      padding: 4px 10px;
      cursor: pointer;
    }

    .top_active {
      background: #018fff;
      color: #f0f8ff;
      border-radius: 30px;
    }
  }
}
</style>
