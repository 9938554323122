<template>
  <div style="width: 100%; height: 400px; overflow: auto">
    <div
      ref="lineChart"
      style="width: 100%; min-width: 600px; height: 100%"
    ></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "LineChart",
  data() {
    return {
      disbursement: {},
      typeXAxis: [],
      seriesData: [],
    };
  },
  mounted() {},
  methods: {
    initData(data) {
      this.disbursement = data;
      // console.log(data,'data')
      this.typeXAxis = JSON.parse(data.yyyydd);
      this.seriesData = [
        {
          name: "收入",
          type: "bar",
          data: JSON.parse(data.seriesIn),
          itemStyle: {
            normal: {
              color: "#5890F5", // 设置折线颜色
            },
          },
        },
        {
          name: "支出",
          type: "bar",
          data: JSON.parse(data.seriesOut),
          itemStyle: {
            normal: {
              color: "#64DCA9", // 设置折线颜色
            },
          },
        },
      ];
      this.initLineChart({
        typeXAxis: this.typeXAxis,
        seriesData: this.seriesData,
      });
    },
    initLineChart({ typeXAxis, seriesData }) {
      const chartDom = this.$refs.lineChart;
      const myChart = echarts.init(chartDom);
      let that = this
      const option = {
        title: {
          text: "",
        },
        grid: {
          //方法 1
          left: 15,
          right: 15,
          bottom: 0,
          top: 80,
          containLabel: true,
        },
        legend: {
          data: ["收入", "支出"],
          // orient: "vertical", // 垂直排列
          // right: 10,
          // top: 0,
          // 使用 formatter 属性自定义图例文本
          formatter: function (name) {
            if (name == "收入")
              return (
                name +
                "(共" +
                that.disbursement.inAmount +
                " 平均" +
                that.disbursement.inAmountAvg +
                "/月)"
              ); // 这里可以根据需要自定义字符串
            if (name == "支出")
              return (
                name +
                "(共" +
                that.disbursement.outAmount +
                " 平均" +
                that.disbursement.outAmountAvg +
                "/月)"
              ); // 这里可以根据需要自定义字符串
          },
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: typeXAxis,
        },
        yAxis: {
          type: "value",
        },
        series: seriesData,
      };

      myChart.setOption(option);
    },
  },
};
</script>

<style scoped lang="scss"></style>
