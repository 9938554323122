<template>
  <div>
    <el-table
      :data="tableData"
      empty-text="暂无数据"
      border
      :span-method="objectSpanMethod"
      :row-class-name="tableRowClassName"
      :cell-class-name="tableCellClassName"
      style="width: 100%"
    >
      <el-table-column prop="type" label="风险等级">
        <template #default="scope">
          <span>{{ typeObj[scope.row.type].text }}</span>
        </template>
      </el-table-column>
      <el-table-column
        type="index"
        width="60px"
        label="序号"
        :index="indexMethod"
      />
      <el-table-column prop="info" label="风险点" />
      <el-table-column prop="infoTip" label="解读建议" min-width="200px">
        <template #default="scope">
          <div>
            <span style="color: red">{{
              scope.row.platform == "ALIPAY" ? "支付宝" : "微信"
            }}</span>
            <span>{{ scope.row.infoTip }}</span>
            <span
              v-if="scope.row.status != 'DETAIL'"
              class="btn_class"
              @click="open(scope.row)"
            >
              查看实例</span
            >
            <span
              v-if="scope.row.status == 'DETAIL'"
              class="btn_class"
              @click="openDrawer(scope.row)"
            >
              查看信息</span
            >
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-model="dialogTableVisible" title="实例">
      <div class="img_pup_box">
        <img
          v-if="type == 'WX'"
          class="preImg"
          src="@/assets/wx_insufficient.png"
        />
        <img
          v-if="type == 'ALIPAY'"
          class="preImg"
          src="@/assets/zfb_insufficient.png"
        />
      </div>
    </el-dialog>

    <el-drawer v-model="drawer" size="90%" direction="rtl">
      <billContent ref="bill" :list="allTradeList"></billContent>
    </el-drawer>
  </div>
</template>

<script>
import billContent from "@/components/billContent/billContent.vue";
export default {
  components: {
    billContent,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    allTradeList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      drawer: false,
      dialogTableVisible: false,
      tableData: [],
      typeObj: {
        G: {
          class: "title_td1",
          thClass: "title_th1",
          text: "优质",
        },
        2: {
          class: "title_td2",
          thClass: "title_th2",
          text: "风险",
        },
        B: {
          class: "title_td3",
          thClass: "title_th3",
          text: "风险",
        },
      },
      type: "WX",
      loading: false,
    };
  },
  watch: {
    list() {
      this.initData();
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.$nextTick(() => {
        this.tableData = [];
        let rowspanIndex = 0;
        let oldRiskPoint = null;
        let oldIndex = 0;
        // eslint-disable-next-line vue/no-mutating-props
        let GList = [];
        let Blist = [];
        this.list.forEach((item) => {
          if (item.type == "B") {
            Blist.push(item);
          }
          if (item.type == "G") {
            GList.push(item);
          }
        });

        let list = [...GList, ...Blist];
        list.forEach((item, index) => {
          if (!oldRiskPoint || oldRiskPoint != item.type) {
            // eslint-disable-next-line vue/no-mutating-props
            list[oldIndex].rowspan = rowspanIndex;
            rowspanIndex = 1;
            oldRiskPoint = item.type;
            oldIndex = index;
          } else {
            rowspanIndex += 1;
            item.rowspan = 0;
          }
          if (index == list.length - 1) {
            // eslint-disable-next-line vue/no-mutating-props
            list[oldIndex].rowspan = rowspanIndex;
          }
          this.tableData.push(item);
        });
        // console.log(this.tableData);
      });
    },
    indexMethod(index) {
      return index + 1;
    },
    objectSpanMethod({ row, columnIndex }) {
      if (columnIndex === 0) {
        if (row.rowspan && row.rowspan != 0) {
          return {
            rowspan: row.rowspan,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    tableRowClassName({ row }) {
      // console.log(this.typeObj[row.type].thClass);
      return this.typeObj[row.type].thClass;
    },
    tableCellClassName(e) {
      if (e.columnIndex == 0) {
        return this.typeObj[e.row.type].class;
      }
      // console.log(e);
    },
    open(item) {
      // 通过组件定义的ref调用uni-popup方法 ,如果传入参数 ，type 属性将失效 ，仅支持 ['top','left','bottom','right','center']
      this.type = item.platform;
      // console.log(this.platform)
      this.dialogTableVisible = true;
    },
    openDrawer(row) {
      this.drawer = true;
      // this.keyword = row.tradeName;
      // console.log(row.primitiveIds)
      this.$nextTick(() => {
        setTimeout(() => {
          let ids = [];
          if (row.primitiveIds) {
            ids = JSON.parse(row.primitiveIds);
          }
          this.$refs.bill.initIds(ids);
        }, 300);
      });
    },
    close() {
      this.dialogTableVisible = false;
    },
  },
};
</script>

<style lang="scss">
.uni-group {
  display: flex;
  align-items: center;
}

.title_td1 {
  background-color: #5ac247 !important;
  border-bottom: 1px solid #5ac247 !important;
  color: #fff !important;
  font-size: 24px !important;
}

.title_th1 {
  background: linear-gradient(
    90deg,
    #dff3d9 0%,
    rgba(80, 178, 255, 0) 100%
  ) !important;
}

.title_td2 {
  background-color: #ffb45c !important;
  border-bottom: 1px solid #ffb45c !important;
  color: #fff !important;
  font-size: 24px !important;
}

.title_th2 {
  background: linear-gradient(
    90deg,
    #fbe8ce 0%,
    rgba(255, 180, 92, 0) 100%
  ) !important;
}

.title_td3 {
  background-color: #ff7c71 !important;
  border-bottom: 1px solid #ff7c71 !important;
  color: #fff !important;
  font-size: 24px !important;
}

.title_th3 {
  background: linear-gradient(
    90deg,
    #f8d7d3 0%,
    rgba(255, 124, 113, 0) 100%
  ) !important;
}

.preImg {
  width: 100%; /* 或指定固定宽度 */
  height: auto;
  object-fit: contain; /* 或其他适合的值 */
}
.el-dialog {
  border-radius: 12px;
}
@media (min-width: 768px) {
  .img_pup_box {
    position: relative;
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    border-radius: 10rpx;
  }
}

@media (max-width: 768px) {
  .el-dialog {
    width: 80%;
  }
  .img_pup_box {
    position: relative;
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    border-radius: 10rpx;
  }
}
</style>
