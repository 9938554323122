<template>
  <div class="animations">
    <div class="radar">
      <div class="dot dot-1"></div>
      <div class="dot dot-2"></div>
      <div class="dot dot-3"></div>
      <div class="cover"></div>
    </div>
    <div style="width: 300px;margin: 20px 0 0 0;">
      <el-progress :show-text="false" :stroke-width="15" :percentage="progress"></el-progress>
    </div>
   
    <div class="progress_text">
      <span style="font-size: 24px;">{{parseInt(progress)}}% </span>
      <span>报告加载中</span>
      <span>
        <span class="subtitle-box-1">。</span>
        <span class="subtitle-box-2">。</span>
        <span class="subtitle-box-3">。</span></span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "loading-radar",
  data() {
    return {
      progress: 0,
      isPlaying: false,
      isCompleted: false,
    };
  },
  methods: {
    start() {
      this.isPlaying = true;
      this.animateProgress(80)
        // .then(() => {
        //   if (!this.isCompleted) {
        //     this.animateProgress(90);
        //   }
        // })
        // .catch((error) => {
        //   console.error("Progress error", error);
        // });
    },
    animateProgress(target) {
      return new Promise((resolve, reject) => {
        let start = this.progress;
        const end = target;
        const duration = (target - start) * 60;

        const doAnimation = () => {
          const elapsed = Date.now() - startTime;
          const progress = Math.min(elapsed / duration, 1);

          this.progress = start + (end - start) * progress;

          if (progress === 1) {
            resolve();
          } else if (this.isCompleted) {
            resolve();
          } else {
            requestAnimationFrame(doAnimation);
          }
        };

        const startTime = Date.now();
        requestAnimationFrame(doAnimation);
      });
    },

  },
};
</script>

<style lang="scss" scoped>
$size: 120px;
$dotSize: 4px;
$maincolor: #4684ff;
.animations {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
}
.radar {
  position: relative;
  flex-shrink: 0;
  z-index: 1;
  height: $size;
  width: $size;
  background: -webkit-repeating-radial-gradient(
    rgba(45, 163, 246, 0) 0%,
    rgba(45, 163, 246, 0) 23%,
    rgba(45, 163, 246, 0.7) 24%,
    rgba(45, 163, 246, 0) 25%
  );
  margin: 0 auto;
  border-radius: 50%;
  border: 2px solid rgba(45, 163, 246, 0.7);
  overflow: hidden;
}

.radar::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: $dotSize;
  height: $dotSize;
  background: $maincolor;
  margin-left: -1px;
  margin-top: -1px;
  border-radius: 1px;
}

.dot {
  position: absolute;
  width: $dotSize;
  height: $dotSize;
  background: $maincolor;
  opacity: 0;
  border-radius: 50%;
  animation: breath 3s linear infinite;
  box-shadow: 0 0 2px 2px rgba(45, 163, 246, 0.5);
}

.dot-1 {
  top: 50px;
  left: 30px;
  animation-delay: 1s;
}

.dot-2 {
  top: 60px;
  right: 20px;
  animation-delay: 0.2s;
}

.dot-3 {
  top: 140px;
  right: 100px;
  animation-delay: 2.3s;
}

.cover {
  transform-origin: bottom right;
  border-right: 1px solid $maincolor;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 45%,
    $maincolor 100%
  );
  width: 50%;
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  animation: rotation 3s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes breath {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  20% {
    opacity: 1;
  }

  40% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.progress_text {
  text-align: center;
  padding-top: 20px;
  color: #4684ff;
  font-size: 20px;
}
@keyframes bounce {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}
.subtitle-box-1 {
  color: #409eff;
  animation: bounce 1s infinite;
}
.subtitle-box-2 {
  color: #409eff;
  animation-delay: 1s;
  animation: bounce 1s infinite;
}
.subtitle-box-3 {
  color: #409eff;
  animation-delay: 2s;
  animation: bounce 1.5s infinite;
}
</style>
