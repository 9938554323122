import axios from "axios";
import SettingMer from "@/utils/settingMer";

const service = axios.create({
  baseURL: SettingMer.apiBaseURL,
  // baseURL: "http://116.205.224.97:82/c", // 从环境变量中获取基础URL
  timeout: 60 * 1000, // 请求超时时间
});

// src/utils/request.js

service.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    const token = localStorage.getItem("token");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    // 展示进度条
    // config.headers['X-Progress'] = 'true';
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    console.error(error);
    return Promise.reject(error);
  }
);

// src/utils/request.js

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // 如果有进度信息，则从响应头中提取
    // 关闭进度条
    if (res.code !== 200) {
      // 处理错误
      console.error(res.message);
      return Promise.reject(new Error(res.message || "Error"));
    } else {
      return res;
    }
  },
  (error) => {
    // 请求失败时处理
    const { config } = error;
    // if (config) {
    //   // 这里假设进度条最大值为100
    //   const progress = ((config.progressEvent.loaded / config.progressEvent.total) * 100) | 0;
    //   // 更新进度信息
    //   // 假设有一个progressData数据属性存储进度信息
    //   Vue.set(this.progressData, config.url, progress);
    // }
    return Promise.reject(error);
  }
);

export default service;
