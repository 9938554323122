<template>
  <div>
    <div class="content_info">
      <div
        v-for="(item, index) in tabList"
        :key="index"
        :class="activeTab == item.name ? 'content_info_item_active' : ''"
        class="content_info_item"
        @click="handleClick(index)"
      >
        <div class="moren">{{ item.name.substring(0, 2) }}</div>
        <div class="moren_text">{{ item.name }}</div>
      </div>
    </div>
    <div class="content_title">
      <div>
        {{ active.name }}
      </div>
      <el-button type="primary" v-if="multipleSelection.length"
        >批量反馈</el-button
      >
    </div>
    <el-table
      ref="multipleTableRef"
      :data="tableData"
      border
      height="300px"
      style="width: 100%; margin-top: 10px"
      empty-text="暂无数据"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column
        type="index"
        width="60px"
        label="序号"
        :index="indexMethod"
        show-overflow-tooltip
      />
      <el-table-column
        prop="orderDate"
        label="交易时间"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="amount"
        label="消费金额"
        show-overflow-tooltip
      />
      <el-table-column
        prop="counterparty"
        label="交易对方"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="orderInstructions"
        label="交易描述"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="inOut"
        label="收支类型"
        show-overflow-tooltip
        >
        <template #default="scope">
          <div> {{ filterData(scope.row.inOut, "billTypeList") }} </div>
        </template>
      </el-table-column>
      <el-table-column prop="billType" label="账单类型" show-overflow-tooltip >
         <template #default="scope">
          <div> {{ filterData(scope.row.platform, "platformList") }} </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right">
        <template #default="scope">
          <div class="btn_class" @click="open(scope.row)"> 反馈 </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
      default: () => [
        {
          id: "1",
          tabName: "趣易租",
          tabTag: "在租",
          imgIcon: "",
          tabList: [
            {
              tradingTime: "2024-08-27 11:43:59",
              tradingObject: "拼多多平台商户",
              transactionDes:
                "99新 华为mate 30Pro 双卡双待 国行正品 租物,租金AuthNo:2023042410002001900596309042",
              consumption: 276.19,
              expenditureType: "不计收支",
              billType: "支付宝",
            },
          ],
        },
        {
          id: "2",
          tabName: "中融小贷",
          tabTag: "在租",
          imgIcon: "",
          tabList: [
            {
              tradingTime: "2024-08-27 11:43:59",
              tradingObject: "拼多多平台商户",
              transactionDes:
                "99新 华为mate 30Pro 双卡双待 国行正品 租物,租金AuthNo:2023042410002001900596309042",
              consumption: 276.19,
              expenditureType: "不计收支",
              billType: "支付宝",
            },
          ],
        },
      ],
    },
  },
  data() {
    return {
      activeTab: "1",
      active: {},
      tableData: [],
      multipleSelection: [],
      platformList: [
        {
          type: 0,
          text: "全部",
        },
        {
          type: "WX",
          text: "微信",
        },
        {
          type: "ALIPAY",
          text: "支付宝",
        },
      ],
      billTypeList: [
        {
          type: 0,
          text: "全部",
        },
        {
          type: "收入",
          text: "收入",
        },
        {
          type: "支出",
          text: "支出",
        },
        {
          type: "不计收支",
          text: "其他",
        },
      ],
    };
  },
  watch:{
    tabList(){
      this.handleClick(0);
    },
  },
  mounted() {
    this.handleClick(0);
  },
  methods: {
    handleClick(index) {
      let active = this.tabList[index];
      this.activeTab = active.name;
      let table = active.list;
      this.tableData = [...table];
      this.active = active;
    },
    filterData(value, item) {
      let text = "";
      if (item) {
        this[item].forEach((key) => {
          if (value == key.type) {
            text = key.text;
          }
        });
      }
      return text;
    },
    indexMethod(index) {
      return index + 1;
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    open() {},
    clearData() {
      this.multipleSelection = [];
      this.$refs.multipleTableRef.clearSelection();
    },
  },
};
</script>

<style lang="scss">
.content_info {
  width: 100%;
  height: 90px;
  overflow: auto;
  display: flex;
  flex-shrink: 0;
  .content_info_item {
    width: 70px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    padding: 0 15px;
    .moren {
      color: #fff;
      font-weight: 700;
      background-color: #0090fb;
      width: 44px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      border-radius: 6px;
    }
    .moren_text {
      width: 100px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
      font-size: 12px;
      margin-top: 4px;
    }

    &:hover {
      background: #f7f7f7;
      cursor: pointer;
      position: relative;
    }
  }
  .content_info_item_active {
    position: relative;
    .moren {
      color: #fff;
      font-weight: 700;
      background-color: #006cb2;
      width: 44px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      border-radius: 6px;
    }
  }
}
.content_title {
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 0 6px 11px;
  font-weight: 700;
  font-size: 14px;
  margin-left: 0;
  background: rgba(1,143,255,0.06);
  border-left: #018FFF 4px solid;
}
@media (max-width: 768px) {
  .content_title {
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px;
  font-weight: 700;
  font-size: 14px;
  margin-left: 0;
  background: rgba(1,143,255,0.06);
  border-left: #018FFF 4px solid;
}
}
</style>
