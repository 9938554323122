import request from "@/utils/request";

export function getDetail(data) {
  return request({
    url: "/report/detailSec?id=" + data.id + "&accountId=" + data.accountId,
    method: "get",
    onUploadProgress: function (e) {
      // console.log('e:',e);
    }

  });
}



// /c/report/detailSec?id=1845406824148307970
