<template>
  <div style="width: 100%; height: 520px; overflow: auto">
    <div ref="lineChart" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "LineChart",
  data() {
    return {
      typeXAxis: [],
      seriesData: [],
    };
  },
  mounted() {},
  methods: {
    initData(data) {
      this.seriesData = [];
      data.forEach((item) => {
        this.seriesData.push({
          value: item.val,
          name: item.tag,
          text: item.text,
        });
      });
      this.initLineChart();
    },
    initLineChart() {
      const chartDom = this.$refs.lineChart;
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            // console.log(params);
            if (params.data.text) {
              return (
                "<div style='width:10px;height:10px;  display: inline-block;margin-right:4px;border-radius: 50%;background: " +
                params.color +
                ";'></div>" +
                params.name +
                ": " +
                params.value +
                "<br/>Tips：" +
                params.data.text
              );
            } else {
              return (
                "<div style='width:10px;height:10px;  display: inline-block;margin-right:4px;border-radius: 50%;background: " +
                params.color +
                ";'></div>" +
                params.name +
                ": " +
                params.value
              );
            }
          },
        },
        legend: {
          type: "scroll",
          bottom: "5%",
          left: "center",
        },

        series: [
          {
            name: "",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.seriesData,
          },
        ],
      };

      myChart.setOption(option);
      // 为饼图添加点击事件监听器
      myChart.on("click", (params) => {
        this.$emit("chartsClick", params.name);
        // 控制台输出点击的数据的信息
        // 在这里可以编写你需要执行的代码，比如打开一个新的页面或者显示更多的信息
      });
    },
  },
};
</script>
