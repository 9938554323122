<template>
  <div style="width: 100%; height: 520px; overflow: auto">
    <div ref="lineChart" style="width: 100%; height: 100%"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "LineChart",
  data() {
    return {
      typeXAxis: [],
      seriesData: [],
    };
  },
  mounted() {},
  methods: {
    initData(data) {
      this.seriesData = [];

      data.forEach((item) => {
        this.seriesData.push({ value: item.val, name: item.tag });
      });

      this.initLineChart();
    },
    initLineChart() {
      const chartDom = this.$refs.lineChart;
      const myChart = echarts.init(chartDom);
      const option = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          type: "scroll",
          orient: "horizontal", // 图例列表的布局朝向，'horizontal'为水平,'vertical'为垂直
          bottom: "5%",
          left: "center",
        },
        series: [
          {
            data: this.seriesData,
            name: "",
            type: "pie",
            radius: ["40%", "70%"],
          },
        ],
      };

      myChart.setOption(option);
      // 为饼图添加点击事件监听器
      myChart.on("click", (params) => {
        this.$emit("chartsClick", params.name);
        // 控制台输出点击的数据的信息
        // 在这里可以编写你需要执行的代码，比如打开一个新的页面或者显示更多的信息
      });
    },
  },
};
</script>
