<template>
  <div style="width: 100%;height: 520px;">
    <div class="rate_box">
      <div class="rate">
        <img class="rate_img" src="@/assets/wx.png" mode="widthFix" />
        
        <div class="rate_item">
          <div class="rate_text">活跃率</div>
          <div class="rate_num">{{ wxRate }}%</div>
        </div>
        <div class="rate_item">
          <div class="rate_text">最长未使用天数</div>
          <div class="rate_num">{{ wxInterval }}天</div>
        </div>
      </div>
      <div class="rate">
        <img class="rate_img" src="@/assets/zfb.png" mode="widthFix" />
        <div class="rate_item">
          <div class="rate_text">活跃率</div>
          <div class="rate_num">{{ aliRate }}%</div>
        </div>
        <div class="rate_item">
          <div class="rate_text">最长未使用天数</div>
          <div class="rate_num">{{ aliInterval }}天</div>
        </div>
      </div>
    </div>
    <div style="width: 100%; height: 400px; overflow: auto">
      <div
        ref="lineChart"
        style="width: 100%; min-width: 600px; height: 100%"
      ></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "LineChart",
  data() {
    return {
      wxInterval: 0,
      wxRate: 0,
      aliInterval: 0,
      aliRate: 0,
      typeXAxis: [],
      seriesData: [],
    };
  },
  mounted() {},
  methods: {
    initData(data) {
      this.typeXAxis = [];

      this.seriesData = [];
      data.forEach((item) => {
        if (item.platform == "WX") {
          this.wxInterval = item.activeInterval;
          this.wxRate = item.activeRate;
        } else if (item.platform == "ALIPAY") {
          this.aliInterval = item.activeInterval;
          this.aliRate = item.activeRate;
        }
        this.typeXAxis.push(...JSON.parse(item.axisXData));
      });
      this.typeXAxis = Array.from(new Set([...this.typeXAxis]));
      let XData = [];
      let axisYData = [];
      data.forEach((item) => {
        XData = [];
        let index = -1;
        axisYData = JSON.parse(item.axisYData);
        JSON.parse(item.axisXData).forEach((key, i) => {
          index = this.typeXAxis.indexOf(key);
          XData[index] = axisYData[i];
        });
        this.seriesData.push({
          name: item.platform == "WX" ? "微信" : "支付宝",
          type: "bar",
          data: XData,
          itemStyle: {
            normal: {
              color: item.platform == "WX" ? "#28C445" : "#06B4FD",
            },
          },
        });
      });

      this.initLineChart({
        typeXAxis: this.typeXAxis,
        seriesData: this.seriesData,
      });
    },
    initLineChart({ typeXAxis, seriesData }) {
      const chartDom = this.$refs.lineChart;
      const myChart = echarts.init(chartDom);

      const option = {
        title: {
          text: "",
        },
        grid: {
          //方法 1
          left: 15,
          right: 15,
          bottom: 0,
          top: 45,
          containLabel: true,
        },
        legend: {
          data: ["微信", "支付宝"],
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: typeXAxis,
        },
        yAxis: {
          type: "value",
        },
        series: seriesData,
      };

      myChart.setOption(option);
    },
  },
};
</script>

<style scoped lang="scss">
.rate_box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  .rate {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #383838;
    margin-top: 5px;

    .rate_img {
      width: 40px;
      height: 40px;
      margin-right: 9px;
    }

    .rate_item {
      width: 100px;
      margin-right: 20px;
      .rate_text{
        font-size: 12px;
        color: #8393A3;
      }
      .rate_num {
        font-size: 20px;
        color: #0D1641;
        overflow: hidden;
      }
    }
  }
}
</style>
