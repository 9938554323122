<template>
  <div class="counterparty-box">
    <el-tabs v-model="activeName" class="demo_tabs" @tab-click="handleClick">
      <el-tab-pane
        v-for="(item, index) in tabList"
        :key="index"
        :label="item.text"
        :name="item.type"
      >
        <el-table
          :data="tableData"
          border
          height="332px"
          style="width: 100%; margin-top: 10px"
          empty-text="暂无数据"
        >
          <el-table-column
            type="index"
            width="60px"
            label="序号"
            :index="indexMethod"
            show-overflow-tooltip
          />
          <el-table-column
            prop="orderDate"
            label="交易时间"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column prop="type" label="命中类型" show-overflow-tooltip>
            <template #default="scope">
              <div>{{ filterData(scope.row.type, "tabList") }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="orderInstructions"
            label="交易描述"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="amount"
            label="交易金额"
            show-overflow-tooltip
          />
          <el-table-column
            prop="billType"
            label="付款方式"
            show-overflow-tooltip
          >
            <template #default="scope">
              <div>
                {{ filterData(scope.row.platform, "platformList") }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="counterparty"
            label="交易对方"
            show-overflow-tooltip
          ></el-table-column>

          <el-table-column prop="inOut" label="收支类型" show-overflow-tooltip>
            <template #default="scope">
              <div>{{ filterData(scope.row.inOut, "billTypeList") }}</div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeName: "OVERDUE",
      tabList: [
        {
          text: "逾期",
          type: "OVERDUE",
        },
        {
          text: "大额",
          type: "LARGE",
        },
        {
          text: "频繁交易",
          type: "FREQUENT",
        },

      ],
      tableData: [],
      platformList: [
        {
          type: 0,
          text: "全部",
        },
        {
          type: "WX",
          text: "微信",
        },
        {
          type: "ALIPAY",
          text: "支付宝",
        },
      ],
      billTypeList: [
        {
          type: 0,
          text: "全部",
        },
        {
          type: "收入",
          text: "收入",
        },
        {
          type: "支出",
          text: "支出",
        },
        {
          type: "不计收支",
          text: "其他",
        },
      ],
    };
  },
  watch: {
    list() {
      this.initData();
    },

    activeName() {
      this.initData();
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.tableData = [];
      this.list.forEach((item) => {
        if (this.activeName == item.type) this.tableData.push(item);
      });
    },
    handleClick() {},
    indexMethod(index) {
      return index + 1;
    },
    filterData(value, item) {
      let text = "";
      if (item) {
        this[item].forEach((key) => {
          if (value == key.type) {
            text = key.text;
          }
        });
      }
      return text;
    },
  },
};
</script>

<style lang="scss">
.bankCardLastNumber {
  display: flex;
  justify-content: flex-start;
  position: relative;
  text-align: center;
  .tagImgBox {
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    .tagImg {
      height: 21px;
      width: 21px;
      margin-left: 5px;
    }
  }
}

@media (min-width: 768px) {
  .counterparty-box {
    .el-tabs__header {
      display: flex !important;
      justify-content: flex-start !important;
      margin-bottom: 0;
      border-bottom: 0;
      .el-tabs__nav {
        border: 0;
      }
      .el-tabs__nav-scroll {
        display: flex !important;
        justify-content: flex-start !important;
      }
    }

    .el-tabs__item {
      border-radius: 10px 10px 0 0;
      background-color: #f4f4f5;
      margin: 0 1px;
      color: #8393a3;
      padding: 0 20px !important;
    }
    .el-tabs__item.is-active {
      border-radius: 10px 10px 0 0;
      background-color: #018fff;
      color: #fff;
    }
  }
}

@media (max-width: 768px) {
  .counterparty-box {
    .el-tabs__header {
      display: flex !important;
      justify-content: flex-start !important;
      padding-right: 20px;
      margin-bottom: 0;
      border-bottom: 0;
      .el-tabs__nav {
        border: 0;
      }
    }

    .el-tabs__item {
      border-radius: 10px 10px 0 0;
      background-color: #f4f4f5;
      margin: 0 1px;
      padding: 0 20px !important;
      color: #8393a3;
    }
    .el-tabs__item.is-active {
      border-radius: 10px 10px 0 0;
      background-color: #018fff;
      color: #fff;
    }
  }
}
</style>
