<template>
  <div id="app">
    <indexPgae/>
  </div>
</template>

<script>
import indexPgae from '@/views/index.vue'
export default {
  name: 'App',
  components:{
    indexPgae
  }
}
</script>
<style lang="scss">
body{
  margin: 0 !important;
}
</style>
